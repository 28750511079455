import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Layout from '../../layout/main';
import Head from '../../layout/head';

import NoImageAvailable from '../../widgets/no-image-available';

const Items = ({ image, label, tag, link }) => {
  let fluid;
  if (image && image.childImageSharp && image.childImageSharp.fluid) {
    fluid = image.childImageSharp.fluid;
  }

  return (
    <div className="mb-2 border-gray-1 d-flex">
      <div className="mr-3">
        <Link to={`${link}`} className="text-decoration-none">
          {fluid ? (
            <Img fluid={fluid} className="minw-130 minh-130 mw-130 mh-130" />
          ) : (
            <NoImageAvailable height={130} width={130} />
          )}
        </Link>
      </div>
      <div className="w-100 product-info-grid py-2 text-left">
        <div className="ganen-bold">{label}</div>
        <div className="fs-14">{tag}</div>
        <Link
          to={`${link}`}
          className="text-decoration-none text-orange fs-12 mt-auto"
        >
          Learn more
        </Link>
      </div>
    </div>
  );
};

const SubCategoryProducts = ({
  category,
  categoryLabel,
  subCategoryLabel,
  products,
}) => {
  return (
    <Layout>
      <Head title={`${categoryLabel} - ${subCategoryLabel}`} />

      <div className="my-5 text-center">
        <div className=" my-5">
          <div className="box-container text-left mb-5">
            <Link
              to="/our-products"
              className="text-black-brown ml-3 text-decoration-none"
            >
              &lt; Back to all categories
            </Link>
          </div>

          <h3 className="fs-35 ganen-green-bold mb-5">Our Products</h3>

          <div className="row">
            <div className="col-6 text-right">
              <Link
                to={`/our-products/${category}`}
                className="text-green-brown ml-3 text-decoration-none fs-20 ganen-green-brown ganen-bold"
              >
                {categoryLabel}
              </Link>
            </div>

            <div className="col-6 text-left p-0 fs-20 ganen-bold">
              <span className="ganen-bold">|</span>
              <span className="pl-3 ganen-bold">{subCategoryLabel}</span>
            </div>
          </div>
        </div>

        <div className="mb-5 sub-categories-grid mw-1500 mx-auto">
          {products.map(({ node }) => {
            return (
              <Items
                key={node.name}
                image={node.images[node.featuredImageIndex - 1]}
                label={node.label}
                tag={node.tag}
                link={`/our-products/${node.category}/${node.subCategory}/${node.name}`}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default SubCategoryProducts;
